<template>

  <div class="main-page-wrapper">
    <!-- <el-container> -->
      <!-- <el-header class="header"> -->
        <div class="theme-main-menu sticky-menu theme-menu-one" :class="{ sticky: isSticky }">
          <div class="inner-content">
            <div class="d-flex align-items-center justify-content-center">
              <div class="d-flex logo order-lg-0"><a href="" class="d-block"><img src="../../../assets/images/imageLand/jielong_ navlogo.png"
                    alt="" width="131" height="36"></a></div>
            
              <div class="left-wiget">
                <nav class="navbar navbar-expand-lg order-lg-2">
                  <button class="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                      <li class="nav-item dropdown">
                        <a class="nav-link" href="#" id="home" style="color:#1877FF">
                          主页
                        </a>
                      </li>
                      <li class="nav-item dropdown">
                        <a class="nav-link" href="#usescene" id="usenav" style="color:#1E2C48">
                          使用场景
                        </a>
                      </li>
                      <li class="nav-item" >
                        <a class="nav-link" href="#template" id="templatenav" style="color:#1E2C48">内容模板</a>
                      </li>
                      <li class="nav-item" >
                        <a class="nav-link" href="#about" id="aboutnav" style="color:#1E2C48">关于我们</a>
                      </li>


                    </ul>
                    <div class="right-wiget d-flex align-items-center order-lg-3 d-lg-none ">
                      <div class="sign-up"><a class="custom-btn"  @click="goLogin()">登录/注册</a></div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      <!-- </el-header> -->
      <!-- <el-main> -->
        <div class="banner-three pt-120 md-pt-80">
				<div class="banner-three-rapper pb-100 md-pb-80">
					<div class="container">	
						<div class="row d-flex align-items-center justify-content-center flex-column text-center">
							<div class="d-flex align-items-center justify-content-center pt-800 md-pt-30"><h1 class="">安全便捷、高效简易、为您 <br/>快速收集信息</h1></div>
							<div class="d-flex align-items-center justify-content-center pt-30"><p class="">整理各类信息、提升工作效率、开启信息管理新篇章</p></div>
							<div class="d-flex align-items-center justify-content-center">
								<img src="../../../assets/images/imageLand/jielong_code.jpg" alt="" width="140px" height="140px" class="code-img">
              </div>
						</div>  
        </div>
					
				</div>
				<div class="Partner partner-three">
					<div class="container">
						<div class="row" style="justify-content: center;">
							<div class="main-content pb-135 md-pb-80" style="padding-bottom: 135px;margin-left: 0;">
								<div  class="content-flex d-flex justify-content-center align-items-center">
                  <div class="item"><img src="../../../assets/images/imageLand/slider01.png" alt="" width="27" height="31"><span>通知公告</span></div>
                  <div class="item"><img src="../../../assets/images/imageLand/slider02.png" alt="" width="32" height="29"><span>团购接龙</span></div>
                  <div class="item"><img src="../../../assets/images/imageLand/slider03.png" alt="" width="29" height="31"><span>接龙打卡</span></div>
                  <div class="item"><img src="../../../assets/images/imageLand/slider04.png" alt="" width="30" height="26"><span>群接龙</span></div>
                  <div class="item"><img src="../../../assets/images/imageLand/slider05.png" alt="" width="24" height="24"><span>通用接龙</span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div  class="dream-job2 dream-job-three pb-160 md-pb-80">
					<div class="container">
						<div class="text-center dream-heading">
							<h3 class="heading-text3  mb-40 ft40"> <span class="span-color">接龙</span>快速创建</h3>
						</div>
						<div class="dremjob-content">
							<div class="row d-flex align-items-center justify-content-between g-5">
								<div class="col-lg-4 md-pb-30">
									<div class="dreamitem-1 text-center">
										<div class="dream-icon dream-icon-one">
											<img src="../../../assets/images/imageLand/zero-01.png" alt="" style="width: 37px;height: 37px;">
										</div>
										<a class="mt-30 mb-5" href="">点击创建接龙</a>
										<p>选择你需要的接龙场景</p>
									</div>
								</div>
								<div class="col-lg-4 md-pb-30">
									<div class="dreamitem-1 text-center">
										<div class="dream-icon dream-icon-two">
                      <img src="../../../assets/images/imageLand/zero-02.png" alt="" style="width: 37px;height: 37px;">
										</div>
										<a class="mt-30 mb-5" href="">自定义或使用模板信息</a>
										<p>选择自定义接龙或使用模板</p>
									</div>
								</div>
								<div class="col-lg-4">
									<div class="dreamitem-1 text-center">
										<div class="dream-icon dream-icon-three">
                      <img src="../../../assets/images/imageLand/zero-03.png" alt="" style="width: 37px;height: 37px;">
										</div>	
										<a class="mt-30 mb-5" href="#">保存并预览</a>
										<p>输入标题填写信息后即可创建</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
      <section class="categoty theme-two-category md-mb-80 pt-50" id="usescene">
				<div class="container">
					<div class="category-rapper pb-50">
						<h3 class="heading text-center pb-50" style="font-size: 44px;"><span style="color:#1877FF;">多重</span>场景覆盖</h3>
						<div class="row first-row pb-20 md-p0 gy-4">
							<div class=" col-lg-4 col-md-6 col-sm-12 mt-24">
								<div class="job-category-one d-flex flex-column align-items-center">
									<div class="job-icon-one job-icon-nine d-flex align-items-center justify-content-center">
										<img src="../../../assets/images/imageLand/cj01.png" alt="">
									</div>
									<h4 class="category-heading ">学校</h4>
									<div class="d-flex" >
										<div class="flex-item mr-10" >
											每日学习情况
										</div>
										<div class="flex-item mr-10">
											作业收集
										</div>
										<div class="flex-item">
											课后复习进度
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-12 mt-24">
								<div class="job-category-one d-flex flex-column align-items-center">
									<div class="job-icon-one job-icon-nine d-flex align-items-center justify-content-center">
										<img src="../../../assets/images/imageLand/cj02.png" alt="">
									</div>
									<h4 class="category-heading">公司</h4>
									<div class="d-flex">
										<div class="flex-item mr-10">
											会议报名
										</div>
										<div class="flex-item mr-10">
											考勤打卡
										</div>
										<div class="flex-item">
											员工信息收集
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-12 mt-24">
								<div class="job-category-one d-flex flex-column align-items-center">
									<div class="job-icon-one job-icon-nine d-flex align-items-center justify-content-center">
										<img src="../../../assets/images/imageLand/cj03.png" alt="">
									</div>
									<h4 class="category-heading ">入职</h4>
									<div class="d-flex">
										<div class="flex-item mr-10">
											订购接龙
										</div>
										<div class="flex-item mr-10">
											社区团购
										</div>
										<div class="flex-item">
											每日商品特价
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-12 mt-24">
								<div class="job-category-one d-flex flex-column align-items-center">
									<div class="job-icon-one job-icon-nine d-flex align-items-center justify-content-center">
										<img src="../../../assets/images/imageLand/cj04.png" alt="">
									</div>
									<h4 class="category-heading">放假</h4>
									<div class="d-flex">
										<div class="flex-item mr-10">
											放假通知
										</div>
										<div class="flex-item mr-10">
											课后复习
										</div>
										<div class="flex-item">
											家庭作业完成情况
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-12 mt-24">
								<div class="job-category-one d-flex flex-column align-items-center">
									<div class="job-icon-one job-icon-nine d-flex align-items-center justify-content-center">
										<img src="../../../assets/images/imageLand/cj05.png" alt="">
									</div>
									<h4 class="category-heading">活动</h4>
									<div class="d-flex">
										<div class="flex-item mr-10">
											活动报名
										</div>
										<div class="flex-item mr-10">
											培训报名
										</div>
										<div class="flex-item">
											交友活动
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-12 mt-24">
								<div class="job-category-one d-flex flex-column align-items-center">
									<div class="job-icon-one job-icon-nine d-flex align-items-center justify-content-center">
										<img src="../../../assets/images/imageLand/cj06.png" alt="">
									</div>
									<h4 class="category-heading">聚餐</h4>
									<div class="d-flex">
										<div class="flex-item mr-10">
											聚会活动
										</div>
										<div class="flex-item mr-10">
											聚会报名
										</div>
										<div class="flex-item">
											公司聚餐
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
      <section class="about-us-three  pb-60 md-pt-80 md-pb-80" id="template">
				<div class="container">
					<div class="about-us-rapper position-relative">
						<div class="row gy-5 align-items-center justify-content-center">
							<div class="col-lg-6 col-xl-6">
								<div class="left-about-three">
									<img src="../../../assets/images/imageLand/bigimg01.png" alt="" class="pic1">
				
								</div>
							</div>
							<div class="col-lg-5 col-xl-5 offset-lg-1 offset-xl-1">
								<div class="right-about md-mt-50">
									<span class="span-three">通用接龙</span>
									<p class="mt-30 mb-20">活动接龙、打卡接龙、群接龙、团购接龙等各种接龙模式供您选择</p>
									<h2 class="heading-2 mt-20">为您全场景信息收集</h2>
									<a  @click="goLogin()">查看更多</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container">
					<div class="about-us-rapper position-relative">
						<div class="row gy-5 align-items-center justify-content-center">
							<div class="col-lg-5 col-xl-5 offset-lg-1 offset-xl-1">
								<div class="right-about md-mt-50">
									<h2 class="heading-2 mt-20">接龙vip<br/>做您传递信息的小助手</h2>
									<p class="mb-20">01、全场景传递信息<br/>02、一分钟快速创建通知 <br/>03、操作简单、效率高</p>
									<a  @click="goLogin()">了解更多</a>
								</div>
							</div>
							<div class="col-lg-6 col-xl-6">
								<div class="left-about-three">
									<img src="../../../assets/images/imageLand/bigimg02.png" alt="" class="pic1">
				
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</section>
      <section id="about" class="section pb-30 py-4 pt-sm-6 position-relative"  data-aos="fade-up" style="padding-top:65px !important; background-color:rgba(24,119,255,0.03) ; ">
					<div class="container text-center">
						<div class="row align-items-center">
							<div class="col">
								<h3 class="fw-medium">关于我们</h3>
			
								<div class="text-center mt-5">
									<a href="" class="d-block d-sm-inline-flex">
										<img src="../../../assets/images/imageLand/jielong_code.jpg" alt="google play" class="aboutus-codeimg"/>
									</a>
								</div>
								<p class="text-muted mx-auto">
									微信扫码免费使用
								</p>
								<p class="text-muted mx-auto">
									接龙vip——对接龙市场需求变化、全新设计的一款微信小程序、体积小巧、功能全面、使用方便
								</p>
							</div>
						</div>
					</div>
					<div class="row">
							<div class="col-lg-12">
								<div class="footer-copyright d-flex align-items-center justify-content-center pt-35">
									<div class="copyright-text">
										
										<p> &copy; Copyright <strong>接龙vip.</strong> All Rights Reserved <a href="https://beian.miit.gov.cn/" rel="noreferrer" target="_blank" style="color: #0d6efd ;">黑ICP备13000410号-107</a> 
										</p>
										<div style="display: flex; align-items: center; justify-content: center;">
											<img width="30" src="../../../assets/images/imageLand/beian_icon.png" alt=""><a href="https://beian.mps.gov.cn/#/query/webSearch?code=23010302001622" rel="noreferrer" target="_blank">黑公网安备23010302001622</a>
										</div>
									
									</div>
								</div>
							</div>
						</div>
				</section>
  </div>

</template>
<script>

import router from '../../../router/index'
export default {
  data() {
    return {
      isSticky: false,
      stickyOffset: 0,
    };
  },
  created() { },
  methods: {
    checkSticky() {
      this.stickyOffset = this.$el.querySelector('nav').offsetTop;
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollPosition >= this.stickyOffset) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    // 跳转登录页
    goLogin(){  
      console.log('123')
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      router.push("/login");
    },
  },
  mounted() {
    window.addEventListener('scroll', this.checkSticky);
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkSticky);
  }
};
</script>
<style lang="scss" scoped>
.main-page-wrapper{
  background-color: #fff;
}
</style>
<style scoped>
.pb-100 {
    padding-bottom: 100px;
}
.pt-120 {
    padding-top: 120px;
}
.mt-30 {
    margin-top: 30px;
}
.mb-5 {
    margin-bottom: 5px !important;
}
.pt-50 {
    padding-top: 50px;
}
.pb-50 {
    padding-bottom: 50px;
}
.mt-24{
  margin-top: 24px;
}
.mb-20{
  margin-bottom: 20px;
}
.mr-10{
  margin-right: 10px;
}
.pt-35{
  padding-top: 35px;
}
.left-wiget {
    margin-left: 140px;
}
.theme-main-menu .navbar {
    font-size: 18px;
  }
.dream-job-three .dreamitem-1 a {
    font-family: Gordita;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.navbar-toggler {
  width: 44px;
  height: 38px;
  padding: 0;
  box-shadow: none;
  position: relative;
  z-index: 99;
  border: none;
  background: #19A463;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler::before,
.navbar-toggler::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 9px;
  width: 26px;
  height: 2px;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background: #fff;
}

.navbar-toggler span {
  position: absolute;
  left: 9px;
  overflow: hidden;
  width: 26px;
  height: 2px;
  margin-top: -1px;
  text-indent: 200%;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  background: #fff;
}

.navbar-toggler::before {
  -webkit-transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
  transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
}

.navbar-toggler::after {
  -webkit-transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
}

.navbar-toggler[aria-expanded="true"] span {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"]::before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.navbar-toggler[aria-expanded="true"]::after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}

.navbar-nav .nav-item {
  padding-right: 50px;
}
.theme-main-menu .inner-content {
    position: relative;
}
.theme-main-menu {
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    padding: 30px 60px;
    transition: all .4s ease-out;

}
.theme-main-menu.sticky-menu.fixed {
  position: fixed;
  z-index: 990;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
  top:0;
  box-shadow: 0 13px 35px -12px rgba(35,35,35,0.1);
  transition: all .4s ease-out;
}
.theme-menu-one.sticky-menu.fixed{
    top:0px;
    padding-left: 20px;
    padding-right: 20px;
}
.theme-main-menu .navbar .navbar-nav .nav-link {
  position: relative;
  color: #505050;
}

.theme-main-menu .navbar .navbar-nav .nav-link::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 3px;
  border-radius: 20px;
  left: 0;
  bottom: 0;
  transform: scale(0, 1);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.theme-main-menu .navbar .navbar-nav .nav-link.active::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 3px;
  border-radius: 20px;
  left: 0;
  bottom: 0;
  transform: scale(1, 1);
  transform-origin: 0 100%;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.theme-main-menu .navbar .navbar-nav .nav-item:hover .nav-link::before {
  transform: scale(1, 1);
  opacity: 1;
}

.theme-main-menu .navbar .navbar-nav .nav-item .nav-link.active,
.theme-main-menu .navbar .navbar-nav .nav-item:hover .nav-link,
.theme-main-menu .navbar .navbar-nav .nav-item:hover .dropdown-toggle::after {
  color: #1877FF;
}

.theme-main-menu .navbar .navbar-nav .nav-item:hover .dropdown-toggle::after {
  transform: rotate(180deg);
}

.dropdown-toggle {
  position: relative;
}

.theme-main-menu .navbar .navbar-nav .dropdown-toggle::after {
  content: "\f282";
  font-family: bootstrap-icons;
  border: none !important;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  line-height: 30px;
  border-radius: 3px;
  color: #000;
  right: -10px;
  top: calc(50% - 14px);
  text-align: center;
  transition: all 0.3s ease;
}

.navbar .show.dropdown-toggle::after {
  transform: rotate(180deg);
}

.navbar-nav .dropdown-menu {
  padding: 6px 13px;
  transition: all 0.3s ease;

}

.navbar-nav .dropdown-menu .dropdown-item {
  padding: 10px 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.dropdown-menu .dropdown-item:hover {
  color: #ffffff;
  background-color: #19a463;
} 

.dropdown-item.active,
.dropdown-item:active {
  background-color: #1877FF;
  color: #ffffff;
}

.nav-link :hover .dropdown-menu .dropdown-item {
  display: block;
  visibility: visible;
  transition: all 0.2s ease-in-out;
}

.navbar .dropdown-menu {
  z-index: 5;
  background-color: #fff;
  border-radius: 10px;
  display: block;
  right: 0;
  left: 0;
  padding: 10px;
  border: none;
  top: 100%;
  visibility: hidden;
  transform: scale(1, 0);
  opacity: 0;
  min-width: 250px;
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.12);
  margin: 0;
  transform-origin: 0 0;
  transition: all 0.3s ease-in-out;
}

.navbar .dropdown-menu .dropdown-menu {
  left: calc(100% + 10px);
  top: 0;
  right: auto;
  min-width: 240px;
  box-shadow: 0 20px 30px -10px rgb(0, 0, 0, 0.15);
  transform: scale(0, 1);
  transition: all 0.3s ease-in-out;
}

.navbar .dropdown:hover>.dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
/*Home page 3 css from here*/
.banner-three-rapper{
  background: url("../../../assets/images/imageLand/bg/bg-6.jpg") center center no-repeat;
  height: auto;
  width: 100%;
  position: relative;
  background-size:cover;
}
.banner-three-rapper h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 90px;
    color: #111D3B;
    max-width: 832px;
    text-align: center;
}
.banner-three-rapper p {
    max-width: 591px;
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    color: #505050;
}
.banner-three-rapper .form-3 {
    width: 100%;
    max-width:740px;
    max-height: 70px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    background: rgba(255 255 255/1);
    box-shadow: 15px 5px 60px rgb(17 29 59 / 8%);
}
.banner-three-rapper .form-3::after{
    content:'';
    position: absolute;
    left:45%;
    height: 70px;
    width: 2px;
    background:rgba(17, 29, 59, 0.2);
}
.banner-three-rapper .form-3 .search-icon{margin-left: 15px;}
.banner-three-rapper .form-3 .placeholder {
    left: -17px;
    position: relative;
}
.banner-three-rapper .form-3 .placeholder input {
    width: 100%;
    outline: none;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #757575;
}
.banner-three-rapper .form-3 .location {
    position: relative;
    padding-left: 15px;
    left: -18px;
}
.banner-three-rapper .form-3 .location a i{padding-left: 15px;transition: .3s ease-in-out;}
.banner-three-rapper .form-3 .location a span{padding-left: 15px;}
.banner-three-rapper .form-3 .button {
    padding-left: 30px;
}
.banner-three-rapper .form-3 .location a:hover{
      color:rgba(28 116 75 / 100%);
}
.banner-three-rapper .form-3 .button a {
    background: #19A463;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 25px 30px;
    transition: .3s ease-in-out;
}
.banner-three-rapper .form-3 .button a:hover{
  color:#ffffff;
  background:rgba(28 116 75 / 100%);
}
.Partner{
  position: relative;
}
 .heading {
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height:3rem;
  color: #111D3B;
  text-align: center;
}
.heading span{
  padding:0 5px;
  color: #19A463;
}
.main-content .content-flex .item{
  padding-right:83px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-slide img{
    margin:auto;
}
.partner-three span {
    font-size: 18px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Bold;
    font-weight: Bold;
    color: #888e9d;
    margin-left: 9px;
}
.dream-job2 {
  position: relative;
}
.dream-job2 .text-center .top-heading{
  font-family: 'Gordita';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  color: #19A463;
}
.dream-job2 .text-center .heading-text3 {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 68px;
    text-align: center;
    color:rgba(17, 29, 59, 1);
}
.dream-job2 .text-center .ft40 {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 68px;
    text-align: center;
    color:#162240;
}

.dream-job2 .dream-heading .heading-text3 .span-color{
  color:#1877FF;;
}
.dreamitem-1 .dream-icon {
    width: 100px;
    height: 100px;
    background: #1877ff;
    color: #ffffff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 100px;
}
.dreamitem-1 .dream-icon i {
    font-size: 40px;
}
.dream-job2 .text-center .heading-text3{font-size: 35px;line-height: 50px;}
.dream-job2 .text-center .heading-text3{min-width: 100%;}
.dream-job-three .dreamitem-1 .dream-icon-one,
.dream-job-three .dreamitem-1 .dream-icon-two,
.dream-job-three .dreamitem-1 .dream-icon-three{
    position: relative;
    background: rgba(24,119,255,0.27);
}
.dream-job-three .dreamitem-1 .dream-icon i{color: rgba(24,119,255,1);}
.dream-job-three .dreamitem-1 .dream-icon:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -16px;
    height: 40px;
    width: 40px;
    background: rgba(24,119,255,1);
    border-radius: 40px;
    line-height:40px;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.dream-job-three .dreamitem-1 .dream-icon-one:before{
    content: '01';
}
.dream-job-three .dreamitem-1 .dream-icon-two:before{
    content: '02';
}
.dream-job-three .dreamitem-1 .dream-icon-three:before{
    content: '03';
}
.dreamitem-1 {
    max-width: 380px;
    height: 236px;
    /* background: linear-gradient(90deg, rgba(25, 164, 99, .05) 0%, rgba(25, 164, 99, 0.25) 100%); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dreamitem-1 a {
    color: #111D3B;
    font-size: 24px;
    font-family: Gordita;
    font-style: normal;
    font-weight: 500;
}
.theme-two-category {
    background:rgba(24,119,255,0.03) url("../../../assets/images/imageLand/bg/bg-2.png") center center no-repeat;
}
.theme-two-category .ctg-1 .c1,
.theme-two-category .ctg-2 .c2,
.theme-two-category .ctg-3 .c3,
.theme-two-category .ctg-4 .c4
 {
    background:rgba(25, 164, 99, 0.1);
}
.theme-two-category .ctg-1:hover .c1,
.theme-two-category .ctg-2:hover .c2,
.theme-two-category .ctg-3:hover .c3,
.theme-two-category .ctg-4:hover .c4
 {
    background:rgba(255, 255, 255, 1);
}
.theme-two-category .ctg-1.active:hover .c1,
.theme-two-category .ctg-2.active:hover .c2,
.theme-two-category .ctg-3.active:hover .c3,
.theme-two-category .ctg-4.active:hover .c4
 {
    background:rgba(255, 255, 255, 1);
}
.heading {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 3rem;
    color: #111D3B;
    text-align: center; 
}
.category-heading{
    font-family: Gordita;
    font-size: 21px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    color: rgba(17, 29, 59, 1);
    transition: all 0.3s ease-in-out;
}
.job-category-one {
    width: 387px;
    height: 354px;
    padding: 8px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 5px 6px 60px 0px rgba(17, 29, 59, 0.04);
    transition: all 0.3s ease-in-out;
}
.flex-item{
    height: 36px;
    border: 1px solid rgba(24, 119, 255, 0.30);
    border-radius: 6px;
    padding: 0 8px;
    line-height: 36px;
    font-size: 18px;
    color: #1877FF;
    font-weight: 700;
}
.d-flex {
    display: flex !important;
}
.pic1{
  max-width: 100%;
}
.about-us-rapper .right-about-two .small{
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    color: #19A463;
}
.about-us-rapper .right-about-two h2.heading-2>.big{
    color: #19A463;
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
}
.about-us-rapper .right-about-two p:nth-child(3){
    max-width:424px;
    position: relative;
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    margin-left: 30px;
}
.about-us-rapper .right-about-two p:nth-child(3)::before {
    position: absolute;
    content: '';
    left:-27px;
    width: 5px;
    height: 86px;
    background: #19A463;
    border-radius: 5px;
    margin: auto 0;
    top: 9px;
}
.about-us-rapper .right-about-two p:nth-child(4) {
    max-width:462px;
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
}
.about-us-rapper .right-about-two a:nth-child(5) {
    width: 158px;
    height: 54px;
    background: #19A463;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    transition: all 0.3s ease-in-out;
}
.about-us-rapper .right-about-two a:nth-child(5):hover{
  color:#ffffff;
  background:rgba(28 116 75 / 100%);
}
.right-about::after{
  position: absolute;
  right: 54px;
  bottom: 44px;
  z-index: -99999;
  transform: rotate(-15deg);
  opacity: .2;
}
.right-about .heading-2 {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 68px;
    color:rgba(17, 29, 59, 1);
    padding-bottom:25px;
}
.right-about p {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    color:#666666;
    /* padding-bottom:30px; */
}
.right-about a {
    background: #1877FF;
    border-radius: 5px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    padding: 14px 47px;
}
.span-three {
    font-family: Gordita;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #1877FF;
    text-align: center;
    border-radius: 24px;
    background: #E6F0FF;
    padding: 11px 37px;
}

.sticky {
  background-color: white;
  position: sticky; /* 使导航条具有吸顶效果 */
  top: 0; /* 设置吸顶后的位置 */
  transition: background-color 0.3s; /* 平滑的背景色过渡效果 */
  padding: 0 30px;
}
.right-wiget .sign-up .custom-btn{
    background: rgba(24, 119, 255, 1);
    border-radius: 5px;
    color: #ffffff;
    padding: 6px 16px;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    transition: all 0.3s ease-in-out;
    margin-left: 183px;
}
@media (min-width: 992px) {
    .container {
        padding-right: 0;
        padding-left: 0;
    }
}
@media (min-width: 1400px) {
    .container{
        max-width: 1320px;
    }
}
</style>